body {
    margin: 0;
    color: $font-color;
    font-family: $font-style;
    font-size: 1.1em;
    line-height: 1.6;
    -webkit-font-smoothing: antialiased;
  }
  h1,
  h2,
  h3 {
    font-weight: 600;
    font-size: 1.5em;
    color: $heading-font-color;
    font-family: $heading-font;
    line-height: 1;
  }
  h1 {
    color: $heading-font-color;
    font-size: 2.5em;
    text-align: left;
    a,
    a:visited {
      text-decoration: none;
      color: $main;
    }
  }
  h2 {
    font-size: 2em;
  }
  a,
  a:visited {
    color: $link-color;
    text-decoration: underline;
    font-weight: bold;
  }
  a:hover {
    color: $link-hover-color;
    text-decoration: underline;
  }
  header {
    background: $header;
    padding: 0px 15px;
    text-align: center;
    margin: 50px 0 0;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  main {
    padding: 0 15px;
    max-width: $content-width;
    text-align: center;
    margin: 0 auto;
  }
  time {
    color: #898989;
  }
  .container {
    max-width: $content-width;
    margin: 0 auto;
  }
  footer {
    font-size: 0.8em;
    text-align: center;
    padding: 40px;
  }