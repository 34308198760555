$content-width: 600px;
$main: #262626;
$font-style: 'Open Sans', sans-serif;
$font-color: #262626;
$link-color: #004b87;
$link-hover-color: #ff671f;
$heading-font: 'Muli', sans-serif;
$heading-font-color: #425469;
$light: #e7edf4;
$header: #1d1425;

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}